import { Box, Button, ListItem, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { MESSAGE_TYPE } from "../../../constants";

const TemplateListItem = ({ template, onEdit }) => (
  <ListItem
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px 0",
      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    }}
  >
    {/* Template Information */}
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {template?.messageType === MESSAGE_TYPE.EMAIL ? (
        <EmailIcon style={{ width: "24px", height: "24px", margin: "19px", color: "#AFB4CF" }} />
      ) : (
        <Box style={{ width: "62px", height: "62px" }}>
          <div style={{ margin: "19px" }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17 19V5H7V19H17ZM17 1C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V21C19 21.5304 18.7893 22.0391 18.4142 22.4142C18.0391 22.7893 17.5304 23 17 23H7C5.89 23 5 22.1 5 21V3C5 1.89 5.89 1 7 1H17ZM9 7H15V9H9V7ZM9 11H13V13H9V11Z"
                fill="#AFB3CF"
              />
            </svg>
          </div>
        </Box>
      )}

      {/* Template Details */}
      <Box>
        <Typography variant="subtitle1" fontWeight="bold">
          {template.name}
          {/* {template.__v !== 0 && ( */}
          {/* <Typography
            component="span"
            sx={{
              backgroundColor: "green",
              color: "white",
              borderRadius: "42px",
              padding: "2px 6px",
              marginLeft: "8px",
              fontSize: "12px",
            }}
          >
            Custom
          </Typography> */}
          {/* )} */}
          {/* {template.__v === 0 && (
            <Typography
              component="span"
              sx={{
                backgroundColor: "transparent",
                color: "black",
                border: "1px solid gray",
                borderRadius: "42px",
                padding: "2px 6px",
                marginLeft: "8px",
                fontSize: "12px",
              }}
            >
              Unused
            </Typography>
          )} */}
        </Typography>
        <Typography variant="body2">{template?.messageType === MESSAGE_TYPE.SMS ? "SMS" : "Email"} Template</Typography>
        {/* <Typography variant="body2">{template?.__v} versions</Typography> */}
      </Box>
    </Box>

    <Box sx={{ flexShrink: 0, textAlign: "right" }}>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "deeppurple",
          color: "white",
          whiteSpace: "nowrap",
        }}
        onClick={() => onEdit(template._id)}
      >
        Edit Template
      </Button>
    </Box>
  </ListItem>
);

export default TemplateListItem;
