import React, { forwardRef, useEffect, useRef } from "react";
import { Quill } from "react-quill";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { MESSAGE_TYPE } from "../../../constants";
import "./styles/quill.snow.css";
import "./styles/style.css";

const Delta = Quill.import("delta");

let Embed = Quill.import("blots/embed");

class TemplateMarker extends Embed {
  static create(value) {
    let node = super.create(value);

    const splitValueTitle = value.title.split(".");

    let formattedTitle = "";

    if (splitValueTitle.length > 1) {
      formattedTitle = splitValueTitle[1]
        .replace(splitValueTitle[0], "")
        .replace(/([A-Z])/g, " $1")
        .toLowerCase()
        .replace(/\bid\b/g, "ID")
        .trim();
    }

    const varKey = splitValueTitle[0].toLowerCase() + "-vars";

    // Set the CSS class
    node.setAttribute("class", `dynamic-variable ${varKey !== "shortenedurl-vars" ? varKey : "no-var-fallback"}`);

    node.setAttribute("data-marker", value.marker);

    node.setAttribute("data-title", value.title);

    node.innerHTML = `${splitValueTitle[0].split(/(?=[A-Z])/).join(" ")}${formattedTitle ? " " + formattedTitle : ""}`;

    if (value.title === "shortenedUrl.shortUrl") {
      node.setAttribute("contenteditable", "false");
      node.innerHTML = "short URL";
    }

    return node;
  }

  static value(node) {
    return {
      marker: node.getAttribute("data-marker"),
      title: node.getAttribute("data-title"),
    };
  }
}

TemplateMarker.blotName = "TemplateMarker";
TemplateMarker.tagName = "span";

Quill.register({
  "formats/TemplateMarker": TemplateMarker,
});

// Editor is an uncontrolled React component
const QuillEditor = forwardRef(
  (
    {
      // readOnly,
      config,
      defaultValue,
      setInnerHtml,
      setInnerPlainText,
      templateType,
      allowedMarkers,
      // onTextChange,
      // onSelectionChange,
    },
    ref,
  ) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);
    // const onTextChangeRef = useRef(onTextChange);
    // const onSelectionChangeRef = useRef(onSelectionChange);

    // useLayoutEffect(() => {
    //   // onTextChangeRef.current = onTextChange;
    //   // onSelectionChangeRef.current = onSelectionChange;
    // });

    // useEffect(() => {
    //   ref.current?.enable(!readOnly);
    // }, [ref, readOnly]);

    useEffect(() => {
      const container = document.getElementById("quill-editor");
      containerRef.current = container;

      const quill = new Quill(container, {
        modules: {
          toolbar: config?.toolbar ?? false,
        },
        theme: "snow",
        ...(!config?.formats && { formats: [TemplateMarker.blotName] }),
      });
      quill.root.setAttribute("spellcheck", false);

      ref.current = quill;
      // quill.clipboard.dangerouslyPasteHTML(
      //   "<p>Hello {{customer.firstName}} {{customer.lastName}}. please use test link to fill application form data</p>",
      // ); // FOr set default html

      const processMarkers = (quill, delta, allowedMarkers) => {
        const ops = delta.ops;
        const markerRegex = /\{\{(.*?)\}\}/g;

        const newDelta = new Delta();

        ops.forEach((op) => {
          if (typeof op.insert === "string") {
            let match;
            let lastIndex = 0;

            while ((match = markerRegex.exec(op.insert)) !== null) {
              const markerText = match[0];
              const markerName = match[1].trim();

              // Insert any text before the marker, retaining attributes/styling
              if (lastIndex < match.index) {
                newDelta.insert(op.insert.slice(lastIndex, match.index), op.attributes || {});
              }

              // Validate the marker
              if (allowedMarkers.includes(markerText.replace(/\s/g, ""))) {
                // Insert the TemplateMarker if valid
                newDelta.insert({ TemplateMarker: { marker: markerText, title: markerName } }, "TemplateMarker");
              } else {
                // Insert the plain text if the marker is invalid
                newDelta.insert(markerText, op.attributes || {});
              }

              lastIndex = match.index + markerText.length;
            }

            if (lastIndex < op.insert.length) {
              newDelta.insert(op.insert.slice(lastIndex), op.attributes || {});
            }
          } else {
            newDelta.insert(op.insert, op.attributes || {});
          }
        });

        quill.setContents(newDelta); // Update Quill with the new Delta
      };

      const convertTextWithLineBreaksToDelta = (inputString) => {
        const lines = inputString.split(/\r?\n/); // Split by new lines (handle both LF and CRLF)
        const delta = new Delta();

        lines.forEach((line, index) => {
          // Insert the line into the delta
          delta.insert(line);
          // Insert a newline after each line except the last
          if (index !== lines.length - 1) {
            delta.insert("\n");
          }
        });

        return delta;
      };

      if (defaultValueRef.current) {
        let delta;
        if (templateType === "email") {
          delta = quill.clipboard.convert(defaultValueRef.current); // Convert HTML to Delta
        } else if (templateType === "sms") {
          delta = convertTextWithLineBreaksToDelta(defaultValueRef.current); // Convert plain text to Delta
        }

        processMarkers(quill, delta, allowedMarkers);
        setInnerHtml(defaultValueRef.current);
        setInnerPlainText(defaultValueRef.current);
      }

      let isHandlingChange = false; // Flag to prevent recursive text change handling

      quill.on(Quill.events.TEXT_CHANGE, () => {
        if (isHandlingChange) return;

        isHandlingChange = true; // Set the flag to prevent recursion

        const delta = quill.getContents();
        const ops = delta.ops;

        const smsShortUrlMarker = {
          markerText: "{{shortenedUrl.shortUrl}}",
          markerTitle: "shortenedUrl.shortUrl",
        };
        const markerRegex = /\{{(.+?)\}}/g;

        let currentIndex = 0;

        // Check if the SMS url marker already exists
        const markerExists = ops.some(
          (op) =>
            op.insert && op.insert.TemplateMarker && op.insert.TemplateMarker.marker === smsShortUrlMarker.markerText,
        );

        ops.forEach((op) => {
          // Only process plain text, skip existing markers (embedded objects)
          if (typeof op.insert === "string") {
            let match;
            while ((match = markerRegex.exec(op.insert)) !== null) {
              const markerName = match[0];
              const startIndex = currentIndex + match.index;

              if (
                allowedMarkers.includes(markerName.replaceAll(/\s/g, "")) &&
                markerName !== smsShortUrlMarker.markerText
              ) {
                // Replace the plain marker text with a TemplateMarker
                quill.deleteText(startIndex, markerName.length, Quill.sources.SILENT); // Use SILENT to avoid triggering TEXT_CHANGE again
                quill.insertEmbed(
                  startIndex,
                  "TemplateMarker",
                  {
                    marker: markerName.replaceAll(/\s/g, ""),
                    title: markerName.replace(/[{}]/g, "").trim(),
                  },
                  Quill.sources.SILENT,
                ); // Insert the TemplateMarker silently

                quill.insertText(startIndex + 1, " ", Quill.sources.SILENT);
                quill.setSelection(startIndex + 2, Quill.sources.SILENT);
              }
            }
          }

          currentIndex += op.insert.length || 1; // Update current index position
        });

        // Ensure the SMS URL marker is immutable and is always at the end
        const lastIndex = quill.getLength() - 1;
        const lastOp = ops[ops.length - 1];

        if (
          !markerExists &&
          allowedMarkers.includes(smsShortUrlMarker.markerText) &&
          templateType === MESSAGE_TYPE.SMS
        ) {
          const lastCharIsNewLine = lastOp && lastOp.insert === "\n";

          if (lastCharIsNewLine) {
            quill.insertEmbed(
              lastIndex - 1,
              "TemplateMarker",
              {
                marker: smsShortUrlMarker.markerText,
                title: smsShortUrlMarker.markerTitle,
              },
              Quill.sources.SILENT,
            );
          } else {
            quill.insertEmbed(
              lastIndex,
              "TemplateMarker",
              {
                marker: smsShortUrlMarker.markerText,
                title: smsShortUrlMarker.markerTitle,
              },
              Quill.sources.SILENT,
            );
            quill.insertText(lastIndex + 1, "\n", Quill.sources.SILENT);
          }
        }

        // Prevent moving the cursor after the last marker
        const markerPosition = quill.getLength() - 2;
        const selection = quill.getSelection();

        if (selection && selection.index > markerPosition) {
          quill.setSelection(markerPosition, Quill.sources.SILENT);
        }

        isHandlingChange = false; // Reset the flag after handling

        let qdc = new QuillDeltaToHtmlConverter(delta.ops, window.opts_ || {});

        qdc.renderCustomWith(function (customOp, contextOp) {
          if (customOp.insert.type === "TemplateMarker") {
            let val = customOp.insert.value;
            return val.marker;
          }
        });

        // Convert the Delta JSON to HTML
        let html = qdc.convert();

        setInnerHtml(html);

        // Convert the Delta to plain text
        const plainTextDelta = quill.getContents();
        let plainTextWithMarkers = "";

        plainTextDelta.ops.forEach((op) => {
          if (typeof op.insert === "string") {
            plainTextWithMarkers += op.insert;
          } else if (op.insert.TemplateMarker) {
            plainTextWithMarkers += `${op.insert.TemplateMarker.marker}`;
          }
        });

        setInnerPlainText(plainTextWithMarkers);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (range) => {
        const markerText = "{{shortenedUrl.shortUrl}}";

        // Check if the marker exists
        const markerExists = quill
          .getContents()
          .ops.some((op) => op.insert && op.insert.TemplateMarker && op.insert.TemplateMarker.marker === markerText);

        if (markerExists && range && templateType === MESSAGE_TYPE.SMS) {
          const markerPosition = quill.getLength() - 2; // Position just before the immutable marker

          if (range.index > markerPosition) {
            quill.setSelection(markerPosition, Quill.sources.SILENT);
          }
        }
      });

      quill.root.addEventListener("copy", (e) => {
        const selection = quill.getSelection();
        if (selection) {
          const html = quill.root.innerHTML;

          e.preventDefault();

          // Set the clipboard data
          e.clipboardData.setData("text/html", html);
        }
      });

      // quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
      //   onSelectionChangeRef.current?.(...args);
      // });

      // Moving toolbar to another container
      // const toolbar = document.querySelector(".ql-toolbar");
      // const toolbarContainer = document.getElementById('test-toolbar');
      // toolbarContainer.appendChild(toolbar);

      return () => {
        ref.current = null;
        container.innerHTML = "";
      };
    }, [ref]);

    return <div ref={containerRef}></div>;
  },
);

QuillEditor.displayName = "QuillEditor";

export default QuillEditor;
